"use client"
import React from 'react'

const WorkingBanner = () => {
  return (
    <div>
      <div className="max-w-7xl mx-auto p-6">
        <p className="text-2xl text-center sm:text-3xl font-normal">How does it work?</p>
        <div className="mt-10 mb-16 grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-4 text-lg text-center">
          <div className="relative flex-1 p-4 pt-20 pb-10 border-2 shadow-lg rounded-sm">
            <p className="uppercase text-lg font-semibold">Sign up</p>
            <p>With your email, and a referral position and post it</p>
          </div>
          <div className="relative p-4 pt-20 pb-10 border-2 shadow-lg rounded-sm">
            <p className="uppercase text-lg font-semibold">Jobseekers</p>
            <p>Submits their resume to the posted position in a single click</p>
          </div>
          <div className="relative p-4 pt-20 pb-10 border-2 shadow-lg rounded-sm">
            <p className="uppercase text-lg font-semibold">Start hiring</p>
            <p>Instantly access all the applicant&apos;s resumes and start hiring</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkingBanner