"use client"
import Link from "next/link"

const Footer = () => {
  return (
    <div>
      <div className="pt-5">
        <div className="max-w-screen-xl px-4 sm:px-6 text-gray-800 sm:grid md:grid-cols-3 sm:grid-cols-2 mx-auto">
          <div className="p-5">
            <h3 className="mb-1 font-bold text-lg">51reqs.com</h3>
            <p>
              Accelerating recruitment through democratized employee referrals, and human to human connections              <br />
            </p>
          </div>
          <div className="p-5 sm:pl-20">
            <div className="text-xl font-semibold">Features</div>
            <Link className="my-1 block text-md" href="/#">Post referrals</Link>
            <Link className="my-1 block text-md" href="/#">Open Jobs</Link>
            <Link className="my-1 block text-md" href="/#">How it works</Link>
          </div>
          <div className="p-5">
            <div className="text-xl font-semibold">51reqs</div>
            <Link className="my-1 block text-md" href="/about">About Us</Link>
            <Link className="my-1 block text-md" href="/privacy">Privacy Policy</Link>
            <Link className="my-1 block text-md" href="/#">FAQs</Link>
          </div>
        </div>
        <div className="p-4">
          <div className="grid col-span-1 justify-items-end text-right">
            <div className="text-sm font-light">
              <p>
                Copyright © 2023, All rights reserved 51reqs.com(Rainbow Riders)
                <Link className="text-blue-500 ml-2" href="/terms">
                  Terms of Service
                </Link>{" "}
                <Link className="text-blue-500 ml-2" href="/privacy">
                  Privacy Policy
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer