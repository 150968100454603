"use client";
import React, { createRef, useEffect, useRef } from 'react'
import { Badge } from '../ui/badge'
import Link from 'next/link';

export default function Skills({ skills }: { skills: { count: number, text: string }[] }) {
  // const skills = [
  //   "React",
  //   "Vue",
  //   "Angular",
  //   "Node",
  //   "Express",
  //   "MongoDB",
  //   "PostgreSQL",
  //   "MySQL",
  //   "Docker",
  //   "Kubernetes",
  //   "AWS",
  //   "GCP",
  //   "Azure",
  //   "Python",
  //   "Java",
  // ]

  // Create a ref for the array of skills
  const refs = useRef<React.RefObject<HTMLDivElement>[]>([]);
  refs.current = skills.map((_, i) => refs.current[i] ?? createRef());

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-slide');
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    refs.current.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      refs.current.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);


  return (
    <div className='px-5 md:px-10 lg:px-20 py-10'>
      <p className='text-3xl'>Explore key skills in demand</p>
      <div className='flex flex-wrap gap-4 my-10'>
        {skills.map((skill, index) => (
          <div ref={refs.current[index]} key={index}>
            <Link href={`/jobs/browse?q=${encodeURIComponent(skill.text)}`}>
              <Badge
                variant="default"
                className={`text-sm sm:text-md py-1.5 px-4 sm:px-10 rounded-full`}
                style={{ animationDelay: `${index * .1}s` }}
              >
                {skill.text}
              </Badge>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}
