"use client"
import React from 'react'
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '../ui/carousel'
import { Card, CardContent } from '../ui/card'
import Autoplay from "embla-carousel-autoplay"

export default function Testimonials() {
  return (
    <div className='px-5 md:px-10 lg:px-20 my-10 '>
      <p className='text-3xl pb-5 text-center'>What Others Are Saying</p>
      <p className='text-xl pb-5 text-center text-gray-600'>It&apos;s not just us, users on 51reqs are loving their experience
      </p>
      <div className='sm:mx-10'>
        <Carousel className="w-full max-w-screen-md md:max-w-screen-lg mx-auto"
          plugins={[
            Autoplay({
              delay: 2000,
            }),
          ]}
        >
          <CarouselContent className="-ml-6">
            {Array.from({ length: 5 }).map((_, index) => (
              <CarouselItem key={index} className="pl-6 sm:basis-1/2 md:basis-1/3 lg:basis-1/4">
                <div className="p-1">
                  <Card>
                    <CardContent className="flex h-64 items-center justify-center p-6">
                      <span className="text-2xl font-semibold">{index + 1}</span>
                    </CardContent>
                  </Card>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      </div>
    </div>
  )
}
