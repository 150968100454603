"use client"
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import CookieConsent from 'react-cookie-consent'

export default function Cookies() {

  const [showCookieBanner, setShowCookieBanner] = useState<boolean>(true)
  useEffect(() => {
    if (window) {
      const consent = window.localStorage.getItem("cookie-consent")
      setShowCookieBanner(consent ? true : false)
    }
  }, [])

  return (
    !showCookieBanner &&
    <div>
      <CookieConsent style={{ background: "white", color: "black" }}
        onAccept={() => {
          localStorage.setItem("cookie-consent", "done")
        }}
        buttonText="I Agree"
        buttonStyle={{ color: "white", fontSize: "14px", background: "#2196F3" }} debug={true}>We use cookies to provide you with better experience. By continuing, you acknowledge that you have read, understood and agreed our <span className="underline">
          <Link href="/cookies">
            Cookie Policy
          </Link>
        </span>
      </CookieConsent>
    </div>
  )
}
